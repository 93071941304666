@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500;600;700&display=swap');

/* width */
::-webkit-scrollbar {
    width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
    box-shadow: inset 0 0 2px rgb(156, 155, 155); 
    border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #cfcfcf; 
    border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #919090; 
}
p, span, a, h1, h2, button, ul, li{
    font-family: "Montserrat", sans-serif;
}
a{
    color: black;
}
ul{
    list-style: none;
}
.bullet_style::before {
    content: "• ";
    color: #00cc81;
    font-size: 24px;
    font-weight: 600;
    line-height: 2px;
}
.hide_mobile{
    margin: 0 auto;
    display: block;
}
.last_paragraph, .paragraph_space{
    margin-bottom: 0 !important;
}
.header_icon img{
    width: 280px;
}
.header_menu{
    margin: initial;
}
.hide_desktop{
    display: none;
}
/* ***MAIN STYLES*** */
body{
    margin: 0;
}
.block_hidden{
    display: none;
}
.block_flex{
    display: flex;
}
.block_flex_space{
    display: flex;
    justify-content: space-between;
}
.block_flex_center{
    display: flex;
    justify-content: center;
}
.wrapper{
    width: 1140px;
    margin: 0 auto;
}
.wrapper_footer{
    width: 1260px;
    margin: 0 auto;
}
.text_bold{
    font-weight: 700;
}
.text_semi_bold{
    font-weight: 600;
}
.text_green{
    color: #00cc81;
}
.text_uppercase{
    text-transform: uppercase;
}
.learn_more{
    width: 160px;
    height: 40px;
    padding: 8px 5px 7px 6px;
    border-radius: 5px;
    background-color: #31a9ff;
    font-size: 13px;
    text-align: center;
    color: #fff;
    border: 0;
    outline: none;
    cursor: pointer;
}
.text_blue{
    color: #31a9ff;
}
/* *** *** * *** *** */

/* ***HEADER STYLES*** */
.header{
    align-items: center;
    height: 80px;
}
.topnav {
    overflow: hidden;
    display: flex;
}
.header button, .lets_talk{
    width: 180px;
    height: 40px;
    padding: 8px 5px 7px 6px;
    border-radius: 5px;
    font-size: 13px;
    font-weight: 600;
    line-height: 1.38;
    text-align: center;
    background-color: #00cc81;
    outline: none;
    border: 0;
    color: #fff;
    cursor: pointer;
}
.product_logo{
    width: 30%;
}
.product_text{
    width: 70%;
}
.product_text p{
    font-size: 13px;
    margin: 0;
    color: #646464;
    font-weight: 500;
}
.expand_product{
    border-bottom: 2px solid #cdcfd2;
    padding: 20px 0;
    align-items: center;
    cursor: pointer;
}
.expand_product_first{
    padding-top: 0px;
}
.expand_product_last{
    padding-bottom: 0px;
    border-bottom: 0;
}
.product_logo img{
    width: 90%;
}
.industries_expand{
    margin-left: 160px;
    width: 430px !important;
}
.industries_expand .product_logo {
    width: 35%;
}
.industries_expand .product_text p {
    width: 268px;
}

.industries_expand .product_logo img{
    width: 25px;
    margin-right: 8px;
    position: relative;
    top: -5px;
}
.industries_expand .product_logo{
    align-items: center;
}
.industries_expand .expand_product{
    align-items: end;
}
.industries_expand .product_logo p{
    font-size: 13px;
}
.links_expand a{
    margin: 0 !important;
    padding: 0 !important;
}

.product_logo p{
    font-size: 11px;
    margin: 0;
}
.topnav .expand {
    background-image: url('/assets/images/arrow-down.svg');
    background-repeat: no-repeat;
    background-position: 100% 50%;
    width: 115px;
    cursor: pointer;
}
.topnav a {
    text-decoration: none;
    padding: 10px 0px;
    margin: 0 15px;
    font-size: 16px;
    font-weight: 600;
}
.expand_industr {
    width: 110px !important;
}
.topnav a:visited{
    color: #000;
}

.menu_links{
    height: 38px;
}

.topnav a:hover {
    border-bottom: 2px solid #00cc81;
    background-position: 100% 55%;
}

.topnav .icon {
    display: none;
}

.links_expand{
    box-shadow: 0 7px 8px 0 #e8e8e8;
    position: absolute;
    top: 60px;
    width: 480px;
    background-color: #fff;
    padding: 20px;
    z-index: 2;
}

.insyglobal_container{
    text-align: center;
    width: 540px;
    height: 240px;
    background-color: #fff;
    box-shadow: 0 7px 8px 0 #e8e8e8;
    padding: 20px;
    margin: 0 auto;
    margin-top: 20px;
}

.insyglobal_container p{
    margin: 0 auto;
    line-height: 20px;
    font-size: 13px;
    margin-bottom: 10px;
}

.container_blocks_right p{
    width: 270px;
}

.container_blocks_left p{
    width: 250px;
}

.insyglobal_container .container_images img {
    width: 165px;
}

/* *** **** *** **** *** */

/* *** homepage_products *** */
.insy_homepage select {
    outline: none;
    border: 0;
    font-size: 22px;
    color: #00cc81;
    text-transform: uppercase;
    font-weight: 700;
    cursor: pointer;
    background-color: transparent;
    font-family: "Montserrat", sans-serif;
}
.homepage_products{
    background-color: #f7f8fa;
}
.products_wrapper{
    width: 950px;
    height: 450px;
    margin: 0 auto;
}
.products_left_heading{
    font-size: 20px;
    font-weight: 700;
    width: 462px;
}
.products_left{
    width: 580px;
}
.products_left_text{
    width: 550px;
    font-size: 16px;
}
.products_left_text_last{
    width: 560px;
}
.expand_border{
    border-bottom: 2px solid #00cc81;
}
.homepage_products .header_info{
    top: 475px;
    left: 14.5%;
    width: 115px;
}
.homepage_products .header_info p{
    top: -30px;
    position: relative;
}
.homepage_products .products_right img{
    position: relative;
    left: 80px;
    top: 43px;
}
.insyglobal_header{
    justify-content: center;
}
.header_info{
    text-align: center;
    position: relative;
    transform: rotate(90deg);
    top: 35px;
    left: -80px;
}
.header_info p{
    font-size: 20px;
    font-weight: 700;
}
.header_info:after{
    background: linear-gradient(to right, #00cc81 35%,#cccccc 35%, #cccccc 50%, #cccccc 50%, #cccccc 75%, #cccccc 75%);
    position: absolute;
    content: '';
    height: 4px;
    right: 0;
    left: 0;
    bottom: 0;
}
.homepage_insyglobal{
    margin-top: -210px;
}
.container_images{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.container_images img{
    width: 50%;
    margin-bottom: 15px;
}
.cube{
    width: 40px !important;
}
.homepage_lines{
    text-align: center;
}
.homepage_lines img{
    width: 755px;
}
.homepage_insy_products{
    width: 880px;
    margin: 0 auto;
}
.homepage_insy_products_bottom{
    width: 580px;
}
.products_block{
    box-shadow: -4px 22px 14px 0 rgb(204 204 204 / 50%);
    width: 234px;
    height: 280px;
    padding: 23px;
    text-align: center;
    background-color: #fff;
}
.homepage_insy_products .container_images img{
    margin: 0;
    width: 90%;
}
.homepage_insy_products .container_images .cube{
    margin-bottom: 15px;
}
.products_block p{
    font-size: 13px;
    line-height: 20px;
    width: 230px;
}
.block1 p{
    margin-top: 35px !important;
}
.block2 p{
    margin-top: 30px !important;
}
.homepage_insy_products .block4 img{
    width: 50%;
}
.homepage_insy_products .block0_1 button{
    margin-top: 20px;
}
.homepage_insy_products .block1 button{
    margin-top: 18px;
}
.homepage_insy_products .block2 button{
    margin-top: 42px;
}
.homepage_insy_products .block3 button{
    margin-top: -4px;
}
.homepage_insy_products .block4 button{
    margin-top: 50px;
}
.home_schema_heading{
    font-size: 20px;
    margin-bottom: 0 !important;
    padding-bottom: 0 !important;
    line-height: 30px;
}
.block3 p, .block4 p{
    margin-top: 0;
}
.block3 .text_bold, .block4 .text_bold{
    margin-top: 5px !important;
    margin-bottom: 0px !important;
}
.homepage_insy_products_upper{
    margin-top: -65px;
    margin-bottom: 30px;
}
/** *** **** *** **** *** **/

/* ***homepage_lets_talk*** */

.homepage_lets_talk{
    text-align: center;
    margin-top: 55px;
    margin-bottom: 67px;
}
.homepage_lets_talk p{
    font-size: 22px;
}

/** *** **** *** **** *** **/

/* *** benefits *** */

.checks_text{
    align-items: flex-start;
}
.homepage_benefits{
    background-color: #31a9ff;
    padding-bottom: 50px;
}
.benefits_row{
    width: 1000px;
    margin: 0 auto;
}
.checks_text img, .templates_left_images_methods img{
    filter: brightness(0) invert(1);
    margin-right: 10px;
}
.homepage_benefits p{
    color: #fff;
    font-size: 13px;
}
.benefits_row_col{
    margin-top: 67px;
}
.homepage_benefits .col_heading{
    font-size: 20px;
    margin: 0;
}
.benefits_row_col .img_size{
    width: 100px;
    height: 120px;
    position: relative;
    top: 25px;
}
.benefits_row_col .img_size2{
    top: 15px;
}
.benefits_row_col .img_size3{
    position: relative;
    top: 15px;
}
.img_size4{
    margin-right: 30px;
}
.content_checks{
    width: 349px;
}
.benefits_row_col{
    margin-right: 30px;
}
.benefits_row_one_col{
    width: 460px;
    margin: 0 auto;
    margin-top: 35px;
}
.homepage_benefits .header_info{
    width: 100px;
    color: #fff;
    top: 120px;
    left: 15%;
}
.homepage_benefits .header_info p{
    font-size: 20px !important;
    position: relative;
    top: -25px;
}
.homepage_benefits .header_info:after{
    background: linear-gradient(to right, #00cc81 35%,#fff 35%, #fff 50%, #fff 50%, #fff 75%, #fff 75%);
}

/* *** ******** *** */


/* *** homepage_business_partners *** */
.partners_how_to_use {
    width: 1080px;
    margin: 0 auto;
    margin-top: -130px;
    margin-bottom: 50px;
    padding: 40px 30px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 17px 2px rgba(170, 170, 170, 0.52);
}
.partners_how_to_use button {
    margin-top: 35px;
}
.block_images_arrows {
    width: 28px;
    height: 10px;
}
.how_to_use_left .block_flex, .how_to_use_right .block_flex{
    justify-content: center !important;
}
.block_images {
    align-items: center;
    justify-content: space-around !important;
}
.block_description {
    width: 160px;
    text-align: center;
    font-size: 12px;
}
.partners_how_to_use .check_text img {
    margin-left: 10px;
}
.partners_how_to_use .check_text p {
    width: 327px;
}
.partners_how_to_use_pet .text_head{
    margin-bottom: 25px;
}
.how_to_use_left h2, .how_to_use_right h2, .partners_how_to_use_pet .text_head{
    text-align: center;
}
.how_to_use_left, .how_to_use_right{
    border: dashed 2px #d8d8d8;
    width: 400px;
    height: 410px;
}
.partners_how_to_use_pet .block_flex {
    justify-content: space-between;
}
.partners_top{
    height: 420px;
    background-image: url('/assets/images/fintech_background.png');
    background-repeat: no-repeat;
    background-size: 100%;
    padding-top: 55px;
}
.partners_top_travel{
    background-image: url('/assets/images/travel_background.png');
}
.partners_top_pet{
    background-image: url('/assets/images/pet_background.png');
}
.partners_top .text_heading{
    width: 825px;
    margin: 20px auto;
}
.partners_top button{
    margin-top: 10px;
}
.partners_how_does{
    width: 893px;
    margin: 0 auto;
    box-shadow: 0 2px 17px 2px rgba(170, 170, 170, 0.52);
    padding: 40px;
    padding-left: 147px;
    margin-top: -50px;
    background-color: #ffff;
}
.partners_how_does .text_head{
    text-align: center;
    margin-bottom: 46px;
}
.how_does_left {
    width: 250px;
    height: 170px;
    border: dashed 2px #d8d8d8;
    border-radius: 6px;
    padding-left: 15px;
}
.online_hr{
    background-color: #d8d8d8;
    height: 2px;
}
.left_retailers p{
    display: flex;
    align-items: center;
    margin: 5px;
}
.left_retailers{
    padding-top: 10px;
    padding-left: 15px;
    padding-right: 10px;
}
.left_retailers .text_bold{
    margin-bottom: 10px;
    font-size: 14px;
}
.left_retailers img{
    width: 12px;
    margin-right: 5px;
}
.how_does_center {
    margin: 0 22px;
}
.how_does_right {
    border: dashed 2px #d8d8d8;
    border-radius: 6px;
    width: 420px;
    height: 170px;
    align-items: center;
}
.right_section_text img{
    width: 24px;
    margin-right: 4px;
}
.right_section_text p{
    display: flex;
    align-items:center;
    width: 190px;
    margin-bottom: 4px;
}
.right_shield p{
    margin-top: 5px;
    font-size: 14px;
}
.right_section_text{
    padding-left: 25px;
}
.partners_how_does .arrows{
    position: relative;
    left: -10px;
}
.online_fintech {
    margin-bottom: 5px;
}
.text_offline_fintech {
    margin: 5px 0; 
}
.element{
    margin: 0 auto;
    width: 72px;
    height: 72px;
    background: #4f3bd7;
    border-radius: 56px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.widgets_element{
    width: 200px;
}
.partners_widgets{
    width: 1180px;
    margin: 60px auto;
    margin-bottom: 0;
}
.partners_widget_integration{
    background-color: #4f3bd7;
    padding-top: 80px;
    padding-bottom: 150px;
}
.partners_widget_image img {
    margin-top: -60px;
    margin-left: -15px;
}
.partners_widget_integration{
    margin-top: -65px;
}
.widget_integration, .widget_integration_api{
    width: 1180px;
    margin: 0 auto;
}
.integration_right{
    width: 581px;
    padding-top: 15px;
}
.check_text{
    align-items: flex-start;
}
.check_text img, .integration_right p img{
    width: 20px;
    margin-right: 16px;
}
.integration_right p img{
    position: relative;
    top: 3px;
}
.check_text p{
    margin-top: 0;
    line-height: 24px;
}
.integration_right .text_mini{
    margin-left: 36px;
}
.right_cms{
    justify-content: space-around;
}
.api_left{
    width: 520px;
}
.api_left .text_uppercase{
    font-size: 20px;
}
.widget_integration_api{
    margin-top: 66px;
}
.partners_how_does_travel, .partners_how_does_pet, .partners_how_does_fintech{
    padding-left: 40px;
    width: 1001px;
}
.pet_image p, .fintech_image p{
    font-size: 14px;
}
.pet_image, .fintech_image{
    width: 105px;
}
.fintech_image img{
    position: relative;
    left: 10px
}
.text_small{
    font-size: 11px;
}
.partners_how_does_fintech .right_section_text p {
    display: flex;
    align-items: center;
    width: 180px;
    margin-bottom: 10px;
}


/* *** ******************* *** */

/* *** homepage_industries *** */

.homepage_industries{
    padding-top: 60px;
}
.industries{
    text-align: center;
}
.homepage_industries_wrapper{
    width: 870px !important;
    margin: 0 auto;
}
.industries .industries_heading{
    font-size: 36px;
    width: 440px;
    margin: 0 auto;
    margin-bottom: 15px;
}
.industries .img_size{
    width: 600px;
    margin: 0 auto;
}
.ind_heading{
    font-size: 20px;
    margin-bottom: 34px;
}
.industries_intermediaries .insy_cube{
    font-size:40px;
    font-weight: 700;
    margin-top:14px;
    margin-bottom:0;
}
.industries_intermediaries .insy_cube span{
    color:#29e297;
    font-size:30px;
}
.industries_ins_comp .ind_heading{
    padding: 0 20px;
}
.industries_retailers .ind_heading{
    position: relative;
    left: -7px;
}
.industries_ins_comp, .industries_intermediaries, .industries_retailers{
    width: 270px;
}
.industries_intermediaries img{
    width:60%;
}
.industries_retailers_content{
    padding-left: 70px;
}
.industries_retailers_content p{
    align-items: center;
    font-weight: 600;
    font-size: 14px;
    margin: 5px 0;
}
.industries_expand .product_logo p{
    line-height: 20px;
}
.schema_content{
    margin-top: -115px !important;
    width: 270px;
    margin: 0 auto;
}
.schema_users {
    margin-top: 45px !important;
    margin-bottom: 15px;
}
.homepage_industries .header_info{
    width: 120px;
    top: 80px;
    left: 14.5%;
}
.homepage_industries .header_info p{
    font-size: 20px !important;
    position: relative;
    top: -25px;
}

/* *** ******************* *** */

/* *** CAROUSEL *** */

.homepage_integrations .header_info, .homepage_clients .header_info{
    width: 120px;
    top: 50px;
    left: 14.5%;
}
.homepage_integrations .header_info p, .homepage_clients .header_info p{
    font-size: 20px !important;
    position: relative;
    top: -30px;
}
.smaller_image {
    width: 80px !important;
}

.homepage_clients{
    margin-top: 60px;
}

.clients_carousel, .integrations_carousel{
    width: 850px;
    margin: 0 auto;
}
.clients .owl-carousel .owl-item img, .integrations .owl-carousel .owl-item img{
    width: 148px;
}
.homepage_clients, .homepage_integrations{
    height: 150px;
}
.owl-carousel .owl-nav button.owl-prev, .owl-carousel .owl-nav button.owl-next{
    transform: scale(2.5);
    position: relative;
    left: -39px;
    top: -30px;
    outline: none;
}
.homepage_integrations{
    margin-bottom: 90px;
}

/* *** ******** *** */

/* *** FOOTER *** */

.right_socials img{
    width: 60px;
}
.right_socials a{
    margin-right: 45px;
}
.left_heading{
    font-size: 36px;
    margin-top: 0;
}
.right_heading{
    font-size: 20px;
    margin-top: 5px;
}
.footer{
    height: 180px;
    padding-top: 40px;
}

/* *** ****** *** */


/* PAGE INSY GLOBAL */
.insy_global_companies_wrapper, .insy_retailers_wrapper, .insy_employee_wrapper, .insy_claims_wrapper, .insy_ai_wrapper{
    width: 980px;
    margin: 0 auto;
}
.companies_section_info, .retailers_section_info, .employee_section_info, .claims_section_info, .ai_section_info{
    background-color: #f7f8fa;
    height: 460px;
}
.right_checks{
    align-items: end;
}
.companies_section_templates, .retailers_section_templates, .employee_section_templates{
    padding: 70px 0;
    background-color: #00cc81;
}
.text_white{
    color: #fff;
}
.companies_section_integrations, .retailers_section_integrations, .employee_section_integrations, .ai_section_integrations{
    height: 240px;
    background-color: #f7f8fa;
}
.tab-content{
    display: none;
}
.tab-content .text_bold{
    font-size: 20px;
}
.section_column_right .current{
    display: block;
}
.section_info_left{
    margin-top: 48px;
}
.section_info_left img{
    width: 200px;
}
.section_info_left p{
    font-size: 20px;
}
.info_left_text1, .info_left_text2{
    font-size: 16px !important;
    width: 570px;
    line-height: 24px;
}
.info_right_tip1, .info_right_tip2{
    text-align: center;
    border-left: 2px solid #cccccc;
    position: relative;
    margin-bottom: -44px;
}
.companies_section_info .section_info_right img{
    margin-top: 30px;
}
.companies_section_ins_products, .retailers_section_ins_products, .employee_section_ins_products, .ai_section_ins_products{
    margin: 60px auto;
}
.ins_products_left{
    width: 415px;
}
.ins_products_left img{
    position: relative;
    left: -100px;
}
.ins_products_right img{
    margin-right: 10px;
    top: 15px;
    position: relative;
}
.ins_products_right .block_flex{
    align-items: flex-start;
}
.templates_heading{
    font-size: 36px;
}
.templates_left .left_text{
    line-height: 24px;
}
.templates_left .left_text_small{
    line-height: 18px;
    font-size: 13px;
}
.templates_left{
    width: 546px;
    margin-left: 26px;
}
.insy_retailers .templates_left{
    width: 563px;
}
.insy_retailers .checks_text img{
    filter: none;
}
.insy_retailers .templates_left_images_methods img{
    filter: brightness(0) invert(1);
    position: relative;
    left: -15px;
}
.templates_left_images_wp{
    margin-bottom: 15px;
}
.templates_left_images{
    width: 380px;
}
.tabs{
    padding: 0;
}
.companies_section_tabs .section_column_right, .retailers_section_tabs .section_column_right, .employee_section_tabs .section_column_right{
    width: 581px;
}
.section_column_left .tabs p{
    font-size: 20px;
    margin-left: 30px;
}
.section_column_left .current p{
    margin-left: 12px;
}
.section_column_left .tabs img{
    width: 18px;
    display: none;
}
.section_column_left .current img{
    display: block;
}
.companies_section_tabs, .retailers_section_tabs, .employee_section_tabs{
    padding: 60px 0;
}
.section_column_left{
    position: relative;
    top: -20px;
}
.tab-link{
    cursor: pointer;
}
.section_column_right .block_flex{
    align-items: flex-start;
}
.section_column_right img{
    margin-right: 10px;
}
.companies_section_integrations, .retailers_section_integrations, .employee_section_integrations, .ai_section_integrations{
    text-align: center;
    padding-top: 1px;
}
.integrations_heading{
    font-size: 36px;
    margin-bottom: 25px;
}
.integrations_types img{
    width: 117px;
}
.inter_heading{
    font-size: 20px !important;
}
.margin{
    margin: 0;
}
.templates_position {
    position: relative;
    left: -30px;
}
.templates_left .margin{
    margin-bottom: 15px;
}

/* **** **** ****** */


/** page retailers **/

.page_insy_retailers .section_info_right, .page_insy_employee .section_info_right{
    margin-top: 48px;
}
.page_insy_retailers .section_info_left img, .page_insy_remployee.section_info_left img{
    width: 275px;
}
.page_insy_retailers .section_info_left_heading {
    font-size: 16px;
    color: #4f3bd7;
    width: 490px;
}
.page_insy_retailers .right_checks, .page_insy_employee .right_checks{
    margin-bottom: 0;
}
.page_insy_employee .ins_products_right{
    padding-top: 138px;
}
.insy_retailers .left_heading{
    margin-bottom: 15px;
}
.page_insy_retailers .ins_products_right{
    padding-top: 0px;
    position: relative;
    top: -10px;
}
.page_insy_retailers .retailers_section_templates{
    background-color: #4f3bd7;
}

/* **** **** ****** */

/* **page_employee** */

.center{
    text-align: center;
}
.page_insy_employee .section_info_left p {
    font-size: 16px;
    padding-right: 60px;
    align-items: center;
}
.page_insy_employee .section_info_left .block_flex img{
    width: auto;
    margin-right: 10px;
}
.insy_employee_img_head{
    width: 200px !important;
}
.text_mini{
    font-size: 13px;
}
.text_heading{
    font-size: 26px;
}
.text_head{
    font-size: 36px;
    margin: 0;
    padding: 0;
}
.retailers_section_hr, .retailers_section_employee{
    padding: 40px 0;
}
.block_left img, .block_right img{
    margin-right: 10px;
    position: relative;
    top: 15px;
}
.block_left .block_flex, .block_right .block_flex{
    align-items: end;
}
.insy_employee .block_left .block_flex, .insy_employee .block_right .block_flex {
    align-items: flex-start;
}
.block_left, .block_right{
    width: 466px;
}

.block_left .text_mini, .block_right .text_mini{
    margin-top: 0;
}
.hr_heading, .employee_heading{
    font-size: 36px;
    margin-top: 0px;
}
.features_block .text_uppercase{
    width: 556px;
    margin: 0 auto;
    text-align: center;
    font-size: 21px;
    margin-bottom: 20px;
}
.features_block .flex_col{
    width: 180px;
}

/*** **** ******** ***/

/* insy_claims */

.text_yellow{
    color: #ffd003;
}
.works_block_left{
    width: 459px;
}
.works_block_right{
    width: 430px;
}
.features_block{
    box-shadow: -4px 22px 14px 0 rgba(204, 204, 204, 0.5);
    background-color: #f7f8fa;
    padding: 30px 0px;
}
.section_insurers, .section_adjuster, .section_provider{
    width: 360px;
    text-align: center;
}
.claims_section_info .section_info_left p {
    font-size: 16px;
    margin-bottom: 10px;
    margin-top: 0;
}
.claims_section_info .section_info_left img {
    width: 280px;
    margin-bottom: 10px;
}
.claims_section_info .section_info_right{
    margin-top: 25px;
}
.insy_claims_works{
    margin-top: 60px;
}
.works_heading, .roles_heading{
    font-size: 36px;
    margin-top: 10px;
    margin-bottom: 20px;
}
.works_block{
    width: 990px;
    margin: 0 auto;
}
.works_block .text_yellow{
    font-size: 60px;
    margin-right: 16px;
    margin-top: 0;
}
.works_block .text_bold{
    line-height: 22px;
}
.features_block .flex_col{
    text-align: center;
}
.insy_claims_features .block_flex_space{
    width: 960px;
    margin: 0 auto;
}
.block_flex_space .text_mini{
    margin-top: 5px;
    line-height: 18px;
}
.insy_claims_roles{
    margin-top: 60px;
    margin-bottom: 60px;
}
.section_height{
    height: 400px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.roles_section .heading{
    font-size: 20px;
    height: 40px;
    margin-bottom: 0;
}
.section_insurers img{
    width: 80%;
}
.section_adjuster img{
    width: 50%;
}
.section_provider img{
    width: 45%;
}
.section_space{
    margin-top: 0;
}

.roles_section p{
    line-height: 24px;
}
.claims_section_info{
    height: 400px !important;
}

/* *********** */

/**** insy_ai ****/
.works_block .text_uppercase{
    font-size: 20px;
    padding-right: 83px;
}
.text_skyblue{
    color: #8ecfff;
}
.page_insy_ai .section_info_left img{
    width: 181px;
}
.insy_ai_works{
    margin-top: 60px;
    margin-bottom: 40px;
}
.works_block .block_left p{
    line-height: 22px;
}
.works_block .left_heading{
    font-size: 20px;
    line-height: 29px;
}
.page_insy_ai .section_info_right{
    margin-left: 125px;
}
.page_insy_ai .claims_section_info {
    height: 420px !important;
}
.works_block .block_left{
    width: 575px !important;
}
.works_block .block_right{
    width: 394px !important;
}
.works_block .block_right img{
    position: relative;
}
.works_block .left_heading{
    padding-right: 115px;
    line-height: 29px !important;
}

/* ************* */

/* ******* about_us ******* */

.aboutus_roadmap{
    margin-bottom: 50px;
}
.roadmap{
    box-shadow: -4px 22px 14px 0 rgba(204, 204, 204, 0.5);
    background-color: #f7f8fa;
}
.aboutus_wrapper{
    width: 1180px;
    margin: 0 auto;
}
.who_are_we_left{
    width: 605px;
}
.who_are_we_right{
    width: 343px;
}
.who_are_we_right img{
    width: 610px;
}

.row_hr{
    justify-content: center;
    margin: 0 auto;
    align-items: center;
    margin-bottom: 15px;
    margin-top: 9px;
}
.row_hr img{
    width: 60px;
}
.roadmap_schema{
    width: 600px;
    position: relative;
    top: -5px;
}
.founders_row_col img{
    width: 104px;
    border-radius: 55px;
}
.roadmap_insincloud img{
    width: 200px;
}
.hr_horizontal{
    background-color: #ccc;
    width: 243px;
    height: 2px;
    margin-left: -2px;
    margin-right: -2px;
}
.meetus_heading_text{
    margin: 0 auto;
    width: 780px;
    margin-bottom: 30px;
}
.roadmap_meetus{
    margin: 50px auto;
}
.roadmap_row_col .text_bold{
    margin-top: 0;
    margin-bottom: 0;
}
.meetus_row_col{
    width: 180px;
    height: 85px;
}
.founders_row_col{
    width: 280px !important;
}
.founders_row_col_cons{
    width: 180px;
}
.meetus_row_last{
    width: 785px;
    margin: 0 auto;
}
.mini_description{
    color: #000 !important;
    line-height: 18px;
}
.aboutus_who_are_we{
    height: 420px;
    background-color: #f7f8fa;
}
.who_are_we_left .left_heading{
    margin-top: 40px;
}
.meetus_heading, .roadmap_heading{
    text-align: center;
    font-size: 36px;
    margin-top: 0;
}
.roadmap{
    padding: 60px 100px;
}
.roadmap_insincloud{
    width: 215px;
    margin: 0 auto;
}
.img_retailers{
    width: 179px;
    margin-bottom: 13px;
}
.years_hr{
    height: 2px;
    width: 145px;
    background-color: rgba(204, 204, 204, 0.5);
}
.row_years{
    justify-content: space-evenly;
    align-items: center;
}
.date_size{
    font-size: 20px;
}
.content_col{
    width: 179px;
}
.col_born{
    margin: 0;
}
.content_col .block_flex{
    flex-direction: column;
}
.content_col img{
    width: 140px;
    margin: 0 auto;
}
.insy_ben_img{
    margin-top: 10px !important;
    width: 120px !important;
}
.insy_dot_img{
    width: 66px !important;
    margin-bottom: -3px !important;
}
.img_employee{
    width: 160px;
}
.img_insy_born{
    width: 80px;
}
.img_ai{
    width: 80px;
}
.img_claims{
    width: 163px;
}
.img_global{
    width: 180px;
    margin-bottom: 18px;
}
.who_are_we_left p{
    padding-right: 20px;
}
.text_img_ai{
    margin-bottom: 8px !important;
}
.roadmap_meetus .text_mini{
    color: #646464;
}
.rectangle {
    width: 53px;
    height: 53px;
    background-color: #00cc81;
}
.mini_width{
    padding: 0 30px;
}
.hr{
    height: 61px;
    border-right: 2px solid #ccc;
    position: relative;
    width: 597px;
    margin: 0 auto;
    margin-top: -25px;
    margin-bottom: 5px;
}

.address_section .section_block{
    width: 290px;
}
.comp_imgs{
    width: 28%;
}

/* ******* ******* ******* */

/* Modal screen */

.red_star{
    color: #ea0303;
    font-weight: 200;
}
.modal{
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 20 !important;
    background-color: rgba(100, 100, 100, 0.67);
}
.modal_content, .modal_content_thank_you{
    width: 380px;
    height: auto;
    padding: 20px 100px;
    margin: 0 auto;
    border-radius: 4px;
    box-shadow: -4px 22px 14px 0 rgb(204 204 204 / 50%);
    background-color: #fff;
    margin-top: 113px;
}
.modal_content .content_heading{
    margin-top: 0;
    font-size: 36px;
}
.close {
    text-align: right;
    height: 20px;
}
.close img{
    position: relative;
    right: -90px;
    cursor: pointer;
}
.content_label p{
    margin-top: 0;
    margin-bottom: 33px;
    font-size: 14px;
    margin-right: 11px;
}
.modal_content input, .modal_content textarea {
    font-weight: 200 !important;
    outline: none;
    border: 0;
    font-size: 14px;
    color: #cfcfcf;
    font-weight: 700;
    cursor: pointer;
    background-color: transparent;
    font-family: "Montserrat", sans-serif;
    margin-bottom: 21px;
    border-bottom: 1px solid #cfcfcf;
    padding-bottom: 10px;
    padding-right: 0;
    width: 100%;
}
.modal_content input::placeholder, .modal_content textarea::placeholder{
    color: #cfcfcf;
    font-weight: 200;
}
.modal_content textarea{
    height: 52px;
    resize: none;
    width: 100%;
}
.conent_input .button_submit{
    width: 100%;
    height: 40px;
    padding: 8px 5px 7px 6px;
    border-radius: 5px;
    font-size: 13px;
    font-weight: 600;
    line-height: 1.38;
    text-align: center;
    background-color: #00cc81;
    outline: none;
    border: 0;
    color: #fff;
    cursor: pointer;
    margin-top: 20px;
}


/* ===== Select Box ===== */
.sel {
  font-size: 14px;
  padding-bottom: 10px;
  font-family: "Montserrat", sans-serif;
  margin-bottom: 20px;
  display: inline-block;
  width: 100%;
  background-color: transparent;
  position: relative;
  cursor: pointer;
  background-image: url('/assets/images/arrow-down-gray.svg');
  background-repeat: no-repeat;
  background-position: 98% 0%;
}

.sel__placeholder {
  display: block;
  font-size: 14px;
  font-family: "Montserrat", sans-serif;
  color: #cfcfcf;
  text-align: left;
  pointer-events: none;
  user-select: none;
  visibility: visible;
}

.sel.active .sel__placeholder {
  visibility: hidden;
}

.sel__placeholder::before {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  content: attr(data-placeholder);
  visibility: hidden;
}

.sel.active .sel__placeholder::before {
  visibility: visible;
}

.sel__box {
  position: absolute;
  top: calc(100%);
  left: -4px;
  display: none;
  list-style-type: none;
  text-align: left;
  font-size: 14px;
  font-family: "Montserrat", sans-serif;
  background-color: #fff;
  width: calc(100% + 8px);
  box-sizing: border-box;
}

.sel.active .sel__box {
  display: block;
  animation: fadeInUp 500ms;
  box-shadow: 0 1px 8px 0 #d9d9d9;
}

.sel__box__options {
  display: list-item;
  font-family: 'Quicksand';
  font-size: 14px;
  font-family: "Montserrat", sans-serif;
  color: #000;
  padding: 0.5em 1em;
  user-select: none;
  font-weight: 600;
}

.sel__box__options.selected::after {
  display: inline;
}

.sel__box__options:hover {
  background-color: transparent;
  color: #00cc81;
}

/* ----- Select Box Black Panther ----- */
.sel {
  border-bottom: 1px solid #cfcfcf;
}

/* ===== Keyframes ===== */
@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translate3d(0, 20px, 0);
  }

  to {
    opacity: 1;
    transform: none;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}


/* ************ */

/* *** Mobile styles *** */
@media screen and (min-width: 2000px) {

    .header_info {
        left: 20% !important;
    }

}
@media screen and (min-width: 2300px) {

    .header_info {
        left: 25% !important;
    }

}
@media screen and (max-width: 1800px) {

    .header_info {
        left: 11% !important;
    }

}

@media screen and (max-width: 1600px) {

    .header_info {
        left: 8% !important;
    }

}

@media screen and (max-width: 1450px) {

    .header_info {
        left: 3% !important;
    }
    .wrapper_footer{
        width: 1140px;
    }

}

@media screen and (max-width: 1270px) {

    .header_info {
        left: 0% !important;
    }

}

@media screen and (max-width: 1140px) {

    .wrapper{
        width: 950px;
    }
    .benefits_row {
        width: 910px;
    }
    .clients_carousel, .integrations_carousel {
        width: 820px;
        margin: 0 auto;
    }
    .products_left {
        width: 500px;
    }
    .products_left_text_last {
        width: 100%;
    }
    .products_left_text {
        width: 100%;
    }

}

@media screen and (max-width: 1000px) {
    
    .products_left,
    .products_wrapper,
    .wrapper,
    .homepage_insy_products,
    .benefits_row,
    .insyglobal_container p,
    .industries_wrapper,
    .content_checks,
    .products_left_heading,
    .industries_ins_comp,
    .industries_intermediaries,
    .industries_retailers,
    .schema_content,
    .clients_carousel,
    .integrations_carousel,
    .industries .industries_heading,
    .footer .lets_talk,
    .info_left_text1, .info_left_text2,
    .insy_global_companies_wrapper,
    .insy_retailers_wrapper, 
    .insy_employee_wrapper, 
    .insy_claims_wrapper, 
    .insy_ai_wrapper,
    .ins_products_left,
    .templates_left,
    .companies_section_tabs .section_column_right, 
    .retailers_section_tabs .section_column_right, 
    .employee_section_tabs .section_column_right,
    .templates_left_images,
    .features_block .text_uppercase,
    .works_block_left, .works_block_right,
    .works_block,
    .insy_claims_features .block_flex_space,
    .section_insurers, 
    .section_adjuster, 
    .section_provider,
    .who_are_we_left,
    .aboutus_wrapper,
    .roadmap_row,
    .roadmap_row_col,
    .who_are_we_right,
    .section_head_left,
    .challenge_left, 
    .challenge_right,
    .achieve_checks,
    .achieve,
    .semi_heading, 
    .meetus_heading_text,
    .meetus_row_last,
    .meetus_row_col,
    .homepage_industries_wrapper,
    .benefits_row_one_col,
    .page_insy_retailers .section_info_left_heading,
    .partners_how_does_travel,
    .partners_how_does_fintech,
    .widget_integration,
    .widget_integration_api,
    .partners_widgets,
    .how_does_right,
    .api_right img,
    .api_left,
    .partners_top .text_heading,
    .pet_image, .fintech_image, 
    .how_does_left,
    .widgets_element,
    .partners_how_to_use,
    .partners_how_to_use .check_text p,
    .how_to_use_left, 
    .how_to_use_right,
    .block_description, .ai_graphic{
        width: 100% !important;
    }
    .industries_retailers .ind_heading, .templates_position{
        left: 0;
    }

    .partners_top{
        background-size: cover;
        padding-bottom: 35px;
        height: auto;
    }
    .pet_image, .fintech_image, .how_does_center{
        text-align: center;
    }
    .text_heading {
        font-size: 20px;
    }
    .how_to_use_left, .how_to_use_right{
        height: auto;
    }
    .butoon_block {
        text-align: center;
    }
    .partners_how_does .text_head{
        font-size: 35px;
        width: 240px;
        margin: 15px auto;
    }
    .pet_image .hide_desktop{
        margin: 10px auto;
    }
    .how_does_center_image {
        width: 130px;
        margin-top: 50px;
    }
    .how_does_center_image_arrows {
        margin: -163px auto 0 auto;
        padding-bottom: 15px;
    }
    .integration_right {
        width: 90%;
        margin: 0 auto;
    }
    .integration_left img{
        width: 90%;
    }
    .integration_left {
        text-align:center;
    }
    .partners_how_to_use .arrows_down {
        margin: 0 auto;
    }
    .widgets_element p{
        width: 200px;
        margin: 15px auto;
        font-weight: 600;
    }
    .partners_how_does, .how_does_left, .partners_how_to_use{
        padding: 20px 0;
        border:0;
    }
    .partners_how_does_pet{
        width: 95% !important;
        margin-top: -20px !important;
        border-radius: 5px;
    }
    .partners_how_does_pet .left_retailers{
        width: 105px;
    }
    .how_to_use_left, .how_to_use_right{
        border: dashed 2px #d8d8d8 !important;
        width: 90% !important;
        margin: 0 auto;
    }
    .ins_products_right .block_flex, .checks_text, .section_column_right .block_flex {
        align-items: flex-start;
    }
    .partners_how_does_pet .how_does_left, .partners_how_does_pet .how_does_right{
        display: flex;
        justify-content: space-around;
        align-items: center;
        border: dashed 2px #d8d8d8;
        width: 90% !important;
        margin: 0 auto;
        padding: 20px 0px;
    }
    .right_section_text p {
        width: 175px;
    }
    .right_section_text {
        padding-left: 15px;
    }
    .block_images {
        justify-content: center !important;
        text-align: center;
    } 
    .how_to_use_left, .how_to_use_right {
        border: 0;
    }
    .partners_how_to_use_pet .check_text{
        display: flex;
    }
    .how_does_left, .how_does_right{
        height: auto;
    }
    .partners_how_does, .partners_widget_image img{
        margin-top: 0;
    }
    .founders_row_col_cons{
        width: 280px;
        margin: 0 auto;
    }
    .products_wrapper, .aboutus_who_are_we{
        height: auto;
    }
    .center_mobile{
        text-align: center;
    }
    .hide_mobile, .years_hr, .arrows_up, .arrows, .block_images_arrows{
        display: none !important;
    }
    .hide_desktop{
        display: block;
    }
    .block_flex, .block_flex_space{
        display: block;
    }
    .block_flex_mobile{
        display: flex;
    }
    .homepage_insyglobal{
        margin-top: 20px;
    }
    .header_info{
        display: none;
    }
    .industries_expand .product_logo img{
        margin-left: 16px;
    }
    .expand_product_first {
        padding-top: 20px;
    }
    .homepage_products .products_right img {
        top: 0;
        left: 0;
        width: 84px;
        margin-top: 30px;
    }
    .insyglobal_container, .products_block{
        width: 270px;
        box-shadow: 0 7px 8px 0 #e8e8e8;
        margin: 20px auto;
    }
    .homepage_insy_products .container_images img {
        margin: 0;
        width: 80%;
    }
    .homepage_insy_products .block4 img{
        width: 50% !important;
    }
    .homepage_insy_products .block4 .cube{
        width: 40px !important;
    }
    .products_block{
        height: auto;
    }
    .homepage_insy_products .learn_more{
        margin-top: 10px !important;
    }
    .benefits_row_col {
        margin-right: 0;
        margin: 0 auto;
        text-align: center;
        margin-top: 40px;
    }
    .content_checks .block_flex_mobile{
        justify-content: unset;
        align-items: flex-start;
        width: 95%;
        text-align: left;
        margin: 10px auto;
    }
    .homepage_benefits .col_heading{
        width: 95%;
        margin: 0 auto;
    }
    .img_size4{
        margin-right: 0;
    }
    .benefits_row{
        padding-top: 1px;
    }
    .schema_content{
        margin-top: 0 !important;
    }
    .industries_retailers_content{
        padding: 0;
    }
    .industries_retailers_content .block_flex_mobile{
        justify-content: center;
    }
    .clients .owl-carousel .owl-item img, .integrations .owl-carousel .owl-item img{
        margin: 0 auto;
    }
    .homepage_products{
        padding: 1px 20px;
    }
    .homepage_products button{
        width: 100%;
    }
    .homepage_lets_talk{
        padding: 0 20px;
    }
    .homepage_lets_talk p, .insy_homepage select{
        font-size: 19px;
    }
    .homepage_benefits .col_heading{
        font-size: 30px;
    }
    .industries_heading{
        font-size: 25px;
    }
    .homepage_industries {
        padding: 20px;
    }
    .ind_heading {
        font-size: 18px;
        margin-bottom: 10px;
    }
    .homepage_integrations{
        margin-bottom: 0;
    }
    .footer_left, .footer_right{
        padding: 0 20px;
    }
    .footer_left .left_heading{
        font-size: 30px;
        margin-bottom: 20px;
    }
    .homepage_clients, .homepage_integrations {
        height: 75px;
    }
    .footer .right_heading{
        margin-top: 20px;
    }
    .right_socials{
        text-align: center;
    }
    .right_socials a{
        margin-right: 0;
    }
    .topnav{
        display: block;
    }
    .menu_links{
        height: auto;
    }
    .burger_icon img{
        width: 40px;
    }
    .header_menu{
        display: none;
        position: absolute;
        background-color: #fff;
        top: 79px;
        width: 100%;
        height: auto;
        z-index: 999;
    }
    .menu_links{
        display: flex;
        flex-direction: column;
        padding-top: 10px;
    }
    .menu_button{
        padding-left: 15px;
        padding-right: 15px;
        padding-bottom: 20px;
    }
    .block_show_mobile{
        display: block !important;
    }
    .links_expand{
        width: 100%;
    }
    .links_expand{
        padding: 0;
    }
    .header_icon{
        padding-left: 15px;
    }
    .topnav .expand{
        width: auto !important;
        text-decoration: none;
        padding: 10px 0px;
        margin: 0 15px;
        font-size: 16px;
        font-weight: 600;
        cursor: pointer;
    }
    .expand_product div{
        padding-left: 15px;
    }
    .industries_expand{
        top: 90px;
    }
    .industries_expand p{
        padding-left: 15px;
    }
    .header button{
        width: 100%;
        margin-top: 10px;
    }
    .modal_content{
        width: 100%;
        padding: 0 15px;
    }
    .conent_input{
        width: 70%;
    }
    .modal_content{
        margin-top: 50px;
        padding: 0;
    }
    .content_label{
        padding-left: 15px;
    }
    .templates_left{
        margin-left: 0;
    }
    .companies_section_info, .retailers_section_info, .employee_section_info, .claims_section_info, .ai_section_info{
        height: auto;
    }
    .ins_products_left img {
        position: relative;
        left: 0;
        width: 100%;
    }
    .section_info_left {
        padding: 0 20px;
    }
    .section_info_right {
        text-align: center;
    }
    .section_info_right img {
        width: 50%;
    }
    .section_info_left .lets_talk{
        width: 100%;
    }
    .ins_products_left{
        text-align: center;
    }
    .ins_products_left p{
        padding: 0 20px;
    }
    .ins_products_left img{
        width: 80%;
    }
    .ins_products_right .block_flex_mobile{
        padding: 0 20px;
    }
    .companies_section_templates, .retailers_section_templates, .employee_section_templates {
        padding: 1px 20px;
        background-color: #00cc81;
    }
    .templates_right{
        margin-top: 20px;
    }
    .templates_right img{
        position: relative;
        left: -20px;
        width: 100%;
        max-width: 480px;
    }
    .companies_section_integrations, .retailers_section_integrations, .employee_section_integrations, .ai_section_integrations{
        height: auto;
        padding-bottom: 20px;
    }
    .companies_section_integrations p, .retailers_section_integrations p, .employee_section_integrations p, .ai_section_integrations p{
        padding: 0 20px;
    }
    .page_insy_retailers .ins_products_right, .page_insy_employee .ins_products_right{
        padding: 1px 20px;
    }
    .page_insy_retailers .ins_products_right .block_flex_mobile, .roadmap{
        padding: 0;
    }
    .retailers_section_tabs .tabs{
        padding: 0 20px;
    }
    .column_block .tabs{
        padding: 0;
    }
    .companies_section_tabs, .retailers_section_tabs, .employee_section_tabs{
        padding: 10px 0;
    }
    .block_left, .block_right{
        width: 100%;
    }
    .hr_blocks, .employee_blocks{
        padding: 0 20px;
    }
    .claims_section_info, .section_height, .page_insy_ai .claims_section_info{
        height: auto !important;
    }
    .section_info_left{
        margin-top: 0;
    }
    .works_block .text_yellow{
        width: 118px;
    }
    .insy_claims_works, .roles_section, .insy_ai_works{
        padding: 0 20px;
    }
    .features_block{
        padding: 30px 20px;
    }
    .features_block .flex_col{
        width: 100%;
        margin-bottom: 40px;
    }
    .roles_section .heading{
        height: 65px;
    }
    .insy_claims_roles{
        margin-bottom: 30px;
    }
    .page_insy_ai .section_info_right{
        margin-left: 0;
    }
    .works_block .block_left, .works_block .block_right{
        width: 100% !important;
    }
    .works_block .left_heading{
        padding-right: 0;
    }
    .who_are_we_right img {
        position: inherit;
        width: 100%;
        max-width: 480px;
        max-height: 300px;
    }
    .roadmap_row_col, .roadmap_insincloud, .founders_row_col{
        width: 80%;
        margin: 50px auto;
    }
    .roadmap{
        padding-bottom: 30px;
    }
    .meetus_row_col{
        height: 50px;
    }
    .meetus_row_col .text_bold{
        margin-bottom: 0;
    }
    .who_are_we_left .left_heading{
        margin-top: 0;
        padding-top: 20px;
    }
    .aboutus_who_are_we{
        padding: 0 20px;
    }
    .content_col, .address_section .section_block{
        margin: 0 auto;
    }
    .row_years{
        display: none !important;
    }
    .homepage_insy_products_upper{
        margin-top: 50px;
    }
    .who_are_we_left{
        margin-bottom: 20px;
    }
    .who_are_we_left button{
        width: 100%;
    }
    .who_are_we_left .left_heading{
        font-size: 30px;
    }
    .close img {
        right: 0px;
    }
    .modal{
        overflow-y: scroll;
    }
    .companies_section_tabs .tabs{
        padding: 0 20px;
    } 
    .column_block .tabs{
        padding: 0;
    }
    .section_head_right img, .industries_achieve .ind_logo_size{
        width: 300px;
    }
    .challenge_left p, .challenge_right p, .achieve p{
        padding: 0 20px;
    }
    .industries_section_head{
        padding: 36px 20px;
    }
    .page_industries_heading{
        line-height: 35px !important;
    }
    .solution {
        width: 95%;
        margin: 0 auto;
    }
    .page_industries_heading{
        font-size: 30px;
    }
    .semi_heading{
        font-size: 16px;
    }
    .templates_left .block_flex, .integration .block_flex, .api_left .block_flex{
        display: flex !important;
    }
    .industries_expand{
        margin-left: 0;
        width: 100% !important;
    }
    .right_cms{
        justify-content: space-around;
    }
    .check_text {
        align-items: flex-start;
    }
    .api_left p, .api_left img{
        padding: 0 20px;
        margin-right: 0;
    }
    .api_right {
        width: 90%;
        margin: 0 auto;
    }
    .how_to_use_center {
        display: flex;
    }
    .api_left .check_text p{
        padding: 0px;
    }
    .right_cms img{
        width: 115px;
    }
    .industries_intermediaries img{
        width:auto;
    }
    .industries_heading {
        font-size: 32px !important;
    }
    


    .products_left p{
        text-align: center;
    }
    .homepage_products .products_wrapper{
        display: flex !important;
        flex-direction: column-reverse !important;
    }
    .green_hr {
        margin: 0 auto;
        width: 35px;
        height: 3px;
        background-color: #00cc81;
        margin-top: 20px;
    }
    .products_block_title p{
        font-size: 20px;
        font-weight: 700;
    }
    .products_block p {
        width: 100%;
    }
    .content_checks {
        margin-top: 20px;
    }
    .checks_text img, .content_checks img{
        width: 16px;
    }
    .industries img{
        width: 100%;
    }
    .homepage_clients {
        margin-top: 30px !important;
    }
    .footer_right .right_socials {
        display: flex;
        justify-content: space-between;
    }
    .section_info_left {
        justify-content: center;
        display: flex;
        align-items: center;
        flex-direction: column;
    }
    .insy_ai_wrapper .section_info_left {
        display: block;
    }
    .section_info_left p{
        text-align: center;
    }
    .companies_section_info_top, .reverse_column {
        display: flex;
        flex-direction: column-reverse;
    }
    .section_info_left_mobile {
        position: absolute;
        top: 110px;
        text-align: center;
    }
    .companies_section_info .section_info_right img{
        margin-top: 130px;
    }
    .templates_left_images {
        margin-bottom: 30px;
    }
    .retailers_section_info .section_info_right img, .insy_claims_wrapper .section_info_right img{
        margin-top: 105px;
    }
    .page_insy_employee .section_info_left p {
        padding-right: 0;
    }
    .insy_employee .section_info_left .block_flex{
        display: flex;
        align-items: flex-start;
        text-align: left;
    }
    .insy_employee_wrapper .block_left .block_flex, .insy_employee_wrapper .block_right .block_flex {
        align-items: flex-start;
    }
    .block_left li, .block_right .paragraph_space li {
        position: relative;
        left: -6px;
    }
    .hr_heading, .employee_heading {
        margin-bottom: 0;
    }
    .insy_ai .section_info_right, .insy_ai_wrapper_top {
        display: flex;
    }
    .insy_ai .section_info_left p{
        text-align: left;
    }
    .insy_ai .section_info_right img{
        width: 128px;
        padding-right: 20px;
    }
    .insy_ai_wrapper_top{
        padding-top: 30px;
    }
    .insy_ai_wrapper_mobile button {
        width: 100%;
    }
    .insy_ai_wrapper_mobile {
        padding: 20px;
    }
    .claims_section_info button{
        margin-bottom: 30px;
    }
    .features_block {
        box-shadow: none;
    } 
    .who_are_we_left p{
        text-align: center;
    }
    .meetus_heading_text {
        width: 90% !important;
    }
    .roadmap_heading {
        padding-top: 30px;
    }
    .aboutus_address .address_section {
        display: block;
    }
    .insy_employee .section_info_left_heading {
        width: 95%;
        margin: 0 auto;
    }

}

/* *** **** 1000px **** *** */

@media screen and (max-width: 400px) {

    .conent_input {
        width: 65%;
    }

}

@media screen and (max-width: 380px) {

    .right_section_text p {
        width: 153px;
    }

}

/* *** **** *** **** *** */

